import React from "react"
import { Carousel, Col, Container, Row } from "react-bootstrap"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import FullWidthLayout from "../components/full_width_layout"

const X = (data) => {
    return <FullWidthLayout pageInfo={{ pageName: "in_design", pageTitle: "IN DESIGN" }}>
    <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />
    <div>
        <Container fluid className="container-full">
          <StaticImage src="../data/commercial/projects/Brickworks/IMG_9837.jpg" className="mb-4 lazyload" alt="" />
        </Container>
        <Container fluid>
          <Row>
            <Col className="text-justify">
              <h1>2020 Frog Song</h1>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Integer a libero ac ante tempus interdum. Donec non purus. 
                Donec sed dolor. Suspendisse faucibus Aenean a nisl non ante</p>
            </Col>
          </Row>
        </Container>
        <Container fluid className="container-full">
          <Row>
            <Col>
              <StaticImage src="../data/commercial/projects/Brickworks/IMG_9837.jpg" className="mb-4 lazyload" />
                <StaticImage src="../data/commercial/projects/Brickworks/IMG_9849.jpg" className="mb-4 lazyload" />
                <Carousel className="mb-4">
                  <Carousel.Item>
                    <StaticImage src="../data/commercial/projects/David_L_Waldron/IMG_9819.jpg" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <StaticImage src="../data/commercial/projects/Brickworks/IMG_9837.jpg" className="lazyload" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <StaticImage src="../data/commercial/projects/Brickworks/IMG_9849.jpg" className="lazyload" />
                  </Carousel.Item>
                </Carousel>
                <StaticImage src="../data/commercial/projects/Brickworks/IMG_9837.jpg" className="mb-4 lazyload" />
            </Col>
          </Row>
        </Container>
    </div>
</FullWidthLayout>
}

/*
export const Data = graphql`
  query ($folder: String) {
    allFile(filter: {sourceInstanceName: {eq: $folder}, relativeDirectory: {eq: "thumbnails"}}, sort: {fields: [name], order: ASC}) {
      nodes {
        id
        name
        relativePath
        sourceInstanceName
        relativeDirectory
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`*/


export default X